import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import Select from "react-select"

const uomArrays = [
    { label: "Ampoule", value: "Ampoule" },
    { label: "Bar", value: "Bar" },
    { label: "Bottle", value: "Bottle" },
    { label: "Box", value: "Box" },
    { label: "Gallon", value: "Gallon" },
    { label: "Jar", value: "Jar" },
    { label: "Pack", value: "Pack" },
    { label: "Tab", value: "Tab" },
    { label: "Tube", value: "Tube" },
    { label: "Vial", value: "Vial" },
]

const initialState = {
    title: '',
    price: 0.00,
    quantity: 1,
    quantity_supplied: 0,
    uom: null
}

const CustomProductModal = ({ visibleModal, toggleModal, onSubmit }) => {
    const [state, setState] = useState(initialState)
    const [error, setError] = useState(false)

    const updateStateForm = (label, value) => {
        setState(prev => {
            let result = value
            if (label === 'price' && isNaN(value)) {
                result = 0.00
            }
            return {
                ...prev,
                [label]: result
            }
        })
    }

    const handleCancel = () => {
        setState(initialState)
        toggleModal()
    }

    const handleSubmit = () => {
        if (!state.title) return setError(true)
        else setError(false)

        const payload = {
            id: null,
            title: state.title,
            quantity: state.quantity,
            remark: null,
            variants: [{
                id: null,
                image: null,
                price: state.price,
                corporate_price: 0,
                uom: !state.uom ? null : state.uom.value,
                quantity_supplied: !state.quantity_supplied ? 0 : state.quantity_supplied
            }]
        }

        onSubmit(payload)
        setState(initialState)
        toggleModal()
    }
    return (
        <Modal
            isOpen={visibleModal}
            toggle={toggleModal}
            size="lg"
        >
            <Form>
                <ModalHeader>Add Custom Product</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Product Name</Label>
                        <Input type='text' value={state.title} onChange={(e) => {
                            setError(false)
                            updateStateForm("title", e.target.value)
                        }} />
                    </FormGroup>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Price</Label>
                                <Input type='number' value={state.price} min="0.00" step="0.01" onChange={(e) => {
                                    updateStateForm("price", parseFloat(e.target.value).toFixed(2))
                                }} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Quantity</Label>
                                <Input type='number' value={state.quantity} min="0" step="1" onChange={(e) => {
                                    updateStateForm("quantity", parseFloat(!e.target.value ? 0 : e.target.value))
                                }} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>UOM</Label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={uomArrays[0]}
                                    value={state.uom}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    name="color"
                                    options={uomArrays}
                                    onChange={(val) => {
                                        if (!val) return updateStateForm("uom", null)
                                        updateStateForm("uom", val)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col>
                            <FormGroup>
                                <Label>Quantity supplied</Label>
                                <Input type='number' value={state.quantity_supplied} min="0" step="1" onChange={(e) => {
                                    updateStateForm("quantity_supplied", parseFloat(e.target.value))
                                }} />
                            </FormGroup>
                        </Col> */}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color='light' className='mr-1' onClick={() => handleCancel()}>Cancel</Button>
                    <Button color='primary' disabled={error} onClick={() => handleSubmit()}>Submit</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default CustomProductModal